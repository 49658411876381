import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
  } from 'react';
import { useHttpApiService } from '../../common/HttpAPI.services';
  
  const APIURL = {
    LOGIN : '/loginUser',
    SIGNUP : '/createUser',
    UPDATE: '/updateProfile',
    REFRESH_TOKEN : '/refresh-token',
    FORGOT_PASSWORD : '/forgot-password',
    RESET_PASSWORD : '/reset-password',
    VALIDATE_TOKEN : '',
    ACTIVATED_KEY : '',
    CONTACT_US : '/support-requests',
    COUNTRIES : '/countries',
    GET_PROFILE_DETAILS : '/users',
  }

  const ApiServiceContext = createContext(null);
  
  export const AuthServiceProvider = (props) => {
    const httpApiService = useHttpApiService();
    const { children } = props;
  
    const getCountries = useCallback(() => {
      return httpApiService?.get(APIURL.COUNTRIES);
    }, [httpApiService]);
  
    const signup = useCallback(
      (data) => {
        return httpApiService?.post(APIURL.SIGNUP, data);
      },
      [httpApiService]
    );

    const login = useCallback(
      (data) => {
        return httpApiService?.post(APIURL.LOGIN, data);
      },
      [httpApiService]
    );
  
    const updateUser = useCallback(
      (data) => {
        return httpApiService?.post(APIURL.UPDATE+"/"+data.id, data);
      },
      [httpApiService]
    );
    const contactUs = useCallback(
      (data) => {
        return httpApiService?.post(APIURL.CONTACT_US, data);
      },
      [httpApiService]
    );
  
    const getProfileDetails = useCallback(
      (userId) => {
        return httpApiService?.get(`${APIURL.GET_PROFILE_DETAILS}/${userId}`);
      },
      [httpApiService]
    );
  
    const createAirdrop = useCallback(
      (data) => {
        return httpApiService?.post("/createAirdrop", data);
      },
      [httpApiService]
    );

    const updateAirdrop = useCallback(
      (data) => {
        return httpApiService?.post("/updateAirdrop/" + data.id, data);
      },
      [httpApiService]
    );

    const joinAirDrop = useCallback(
      (data) => {
        return httpApiService?.post("/joinAirdrop", data);
      },
      [httpApiService]
    );

    const addQuestion = useCallback(
      (data) => {
        return httpApiService?.post("/addQuestion", data);
      },
      [httpApiService]
    );

    const checkEmail = useCallback(
      (data) => {
        return httpApiService?.post("/checkEmail/"+data.id, data);
      },
      [httpApiService]
    );

    const verifyEmail = useCallback(
      (data) => {
        return httpApiService?.post("/verifyEmail/"+data.id, data);
      },
      [httpApiService]
    );

    const forgotPassword = useCallback(
      (data) => {
        return httpApiService?.post("/forgotPassword/"+data.id, data);
      },
      [httpApiService]
    );
    const resetPassword = useCallback(
      (data) => {
        return httpApiService?.post("/reset-password/"+data.id, data);
      },
      [httpApiService]
    );
    const getAirdropListData = useCallback(
      () => {
        return httpApiService?.get(`/getAirDropListData`);
      },
      [httpApiService]
    );

    const getWinnerData = useCallback(
      () => {
        return httpApiService?.get(`/getWinnerData`);
      },
      [httpApiService]
    );

    const createWinner = useCallback(
      (data) => {
        return httpApiService?.post(`/createWinner`,data);
      },
      [httpApiService]
    );

    const deleteWinner = useCallback(
      (data) => {
        return httpApiService?.remove(`/deleteWinner/${data.id}`,data);
      },
      [httpApiService]
    );

    const deleteAirdrop = useCallback(
      (id) => {
        console.log(id)
        return httpApiService?.remove(`/deleteAirdrop/${id}`,{});
      },
      [httpApiService]
    );

    const getJoinedAirdrop = useCallback(
      (id) => {
        return httpApiService?.get(`/getAirDropListData/${id}`);
      },
      [httpApiService]
    );

    const getAirdropList = useCallback(
      () => {
        return httpApiService?.get(`/getAirDropList`);
      },
      [httpApiService]
    );

    const getContactUsQuestions = useCallback(
      () => {
        return httpApiService?.get(`/getContactUsQuestions`);
      },
      [httpApiService]
    );

    const updateQuestion = useCallback(
      (data) => {
        return httpApiService?.post("/updateQuestion/" + data.id, data);
      },
      [httpApiService]
    );

    const addWithdrawRequest = useCallback(
      (data) => {
        return httpApiService?.post("/addWithdrawRequest", data);
      },
      [httpApiService]
    );

    const getWithdrawRequest = useCallback(
      () => {
        return httpApiService?.get(`/getWithdrawRequest`);
      },
      [httpApiService]
    );

    const updateWithdrawRequest = useCallback(
      (data) => {
        return httpApiService?.post("/updateWithdrawRequest/" + data.id, data);
      },
      [httpApiService]
    );

    const getUsers  =  useCallback(
      () => {
        return httpApiService?.get(`/usersList`);
      },
      [httpApiService]
    );

    const getTransactionHistory = useCallback(
      (id) => {
        return httpApiService?.get(`/getWithdrawRequest/${id}`);
      },
      [httpApiService]
    );

    const operationsAllowed = useMemo(
      () => ({
        addWithdrawRequest,
        updateWithdrawRequest,
        getWithdrawRequest,
        signup,
        getCountries,
        getUsers,
        getContactUsQuestions,
        updateQuestion,
        forgotPassword,
        resetPassword,
        login,
        getAirdropList,
        updateUser,
        getProfileDetails,
        contactUs,
        createAirdrop,
        updateAirdrop,
        deleteAirdrop,
        joinAirDrop,
        addQuestion,
        verifyEmail,
        getJoinedAirdrop,
        checkEmail,
        getAirdropListData,
        getWinnerData,
        createWinner,
        deleteWinner,
        getTransactionHistory
      }),
      [forgotPassword,
        getContactUsQuestions,
        getAirdropListData,
        deleteAirdrop,
        getUsers,
        resetPassword,
        updateAirdrop,
        getCountries,
        checkEmail,
        getProfileDetails,
        signup,
        getAirdropList,
        contactUs,
        createAirdrop,
        joinAirDrop,
        addQuestion,
        getJoinedAirdrop,
        getWinnerData,
        updateQuestion,
        addWithdrawRequest,
        updateWithdrawRequest,
        getWithdrawRequest,
        createWinner,
        deleteWinner,
        verifyEmail,
        getTransactionHistory
      ]
    );
  
    return (
      <ApiServiceContext.Provider value={operationsAllowed}>
        {children}
      </ApiServiceContext.Provider>
    );
  };
  
  export const useApiService = () => {
    return useContext(ApiServiceContext);
  };
  