import "./App.css";
import { NotificationContainer } from "react-notifications";
import AppRoutes from "./AppRoutes";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from "react-router-dom";
import { HttpApiServiceProvider } from "./modules/common/HttpAPI.services";
import { AuthServiceProvider } from "./modules/auth/services/Auth.service";
function App() {
  return (
    <BrowserRouter>
      <NotificationContainer />
      <HttpApiServiceProvider>
        <div className="App ">
          <AuthServiceProvider>
            <AppRoutes />            
          </AuthServiceProvider>
        </div>
      </HttpApiServiceProvider>
    </BrowserRouter>
  );
}

export default App;
