import { USER,AIRDROP,FETCH_DATA,LIST_FETCHED } from "./constants";

const initialState = {
  isLoading: false,
  productList: null,
  data : localStorage.getItem('userDetails')
  ? JSON.parse(localStorage.getItem('userDetails'))
  : null
}

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case USER:
      return { ...state, data:action.data};
    case AIRDROP:
      return { ...state,productList:action.data};
      case FETCH_DATA : 
      return {
          ...state,
          isLoading: true
      };
      break;
    case LIST_FETCHED : 
      return {
          ...state,
          productList: action.data
      };
      break;
      
    default:
      return initialState;
  }
};
