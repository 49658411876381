import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from './modules/common/presentations/PageNotFound';

const HomePage = React.lazy(() => import('./modules/home/Home'));

const  AppRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<HomePage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;