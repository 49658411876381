import {
    createContext,
    useContext,
    useEffect,
    useMemo,
  } from 'react';
  import axios from 'axios';
//   import { userQuery } from '../../auth/states/user.query';
  
  const HttpApiServiceContext = createContext(
    null
  );
  const BASE_URL = process.env.REACT_APP_API_URL || 'https://airdrop-web.onrender.com';
  // const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  
  const getHeaders = () => {
    return {
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
  };
  
  const getFileHeaders = () => {
    return {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/octet-stream',
        responseType: 'arraybuffer',
      },
    };
  };
  
  const getFormHeaders = () => {
    return {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };
  };
  
  const handleError = (error) => {
    let errorMessage = 'Something went wrong, Please try after sometime.';
    const err = { ...error };
    console.log("err", err)
    if (err?.response?.status !== 200) {
      if (err?.response?.data?.error?.message) {
        errorMessage = err?.response?.data?.error?.message;
      } else {
        errorMessage = err?.message;
      }
    }
    return errorMessage;
  };
  
  const get = (path, header = getHeaders()) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}${path}`, header)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const post = (path, data, headers = getHeaders()) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}${path}`, data, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const getFile = (path, header = getFileHeaders()) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}${path}`, header)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const patch = (path, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${BASE_URL}${path}`, data, getHeaders())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const postFormData = (path, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}${path}`, data, getFormHeaders())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const putFormData = (path, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URL}${path}`, data, getFormHeaders())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const put = (path, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URL}${path}`, data, getHeaders())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  const remove = (path, headers = getHeaders()) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URL}${path}`, headers)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  };
  
  export const HttpApiServiceProvider = (props) => {
    const { children } = props;
    useEffect(() => {
    //   axios.interceptors.request.use(async (request) => {
    //     const { session } = userQuery.getValue();
    //     if (!request.url?.includes('show-credentials')) {
    //       request.headers.Authorization = `Bearer ${session?.accessToken}`;
    //     }
  
    //     return request;
    //   });
  
      axios.interceptors.response.use(
        async (res) => {
          return res;
        },
        async (err) => {
          try {
            // const { session } = userQuery.getValue();
            if (
              err?.response?.data?.statusCode === 401 
            //   session &&
            //   session.accessToken !== ''
            ) {
              err.config.headers = {
                ...err.config.headers,
                // Authorization: `Bearer ${session?.accessToken}`,
              };
              return await axios.request(err.config);
            }
            // return Promise.reject(err);
          } catch (error) {
            return Promise.reject(error);
          }
  
          return Promise.reject(err);
        }
      );
    }, []);
  
    const operationsAllowed = useMemo(
      () => ({
        get,
        put,
        putFormData,
        post,
        postFormData,
        remove,
        patch,
        getFile,
      }),
      []
    );
  
    return (
      <HttpApiServiceContext.Provider value={operationsAllowed}>
        {children}
      </HttpApiServiceContext.Provider>
    );
  };
  
  export const useHttpApiService = () => {
    return useContext(HttpApiServiceContext);
  };
  